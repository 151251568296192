import LogoutConfirmationDialog from "@/components/dialogs/LogoutConfirmationDialog";
import Header from "@/components/header/Header";
import React, { useEffect, useState } from "react";

interface LayoutProps {
  children: React.ReactNode;
  headerTitle: string;
}

const Layout: React.FC<LayoutProps> = ({ children, headerTitle }) => {
  const [containerHeight, setContainerHeight] = useState("100vh");

  useEffect(() => {
    const updateHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      setContainerHeight(`calc(100 * var(--vh, 1vh) - 4rem)`); // Subtracting 4rem for potential header/footer
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);
  return (
    <div className="min-h-screen flex flex-col ">
      <Header title={headerTitle} />
      <main className="flex-1 px-4 ">
        <div className="max-w-4xl mx-auto">
          <div
            className="flex flex-col w-full  mx-auto  overflow-hidden py-3"
            style={{ height: containerHeight }}
          >
            {children}
          </div>
        </div>
      </main>
      <LogoutConfirmationDialog />
    </div>
  );
};

export default Layout;
