import axios from "axios";
import { create_litige_url } from "../constants/urls";

import { store } from "../store/store";
import { defaultHeadersWithToken } from "../constants/constants";
import { Litige } from "@/interfaces/Litige";

export async function createLitige(litige: Litige): Promise<boolean> {
  const accessToken = store.getState().authentication.accessToken;
  try {
    const response = await axios({
      url: create_litige_url,
      headers: defaultHeadersWithToken(accessToken),
      data: litige,
      method: "POST",
    });
    let data = response.data;

    if (data.success === true) {
      return data.success;
    } else {
      // Handle unsuccessful creation
    }
  } catch (error) {}
  return false;
}
