import React from "react";
import { Button } from "../ui/button";
import { LoaderCircle } from "lucide-react";

interface ActionButtonProps {
  label: string;
  handleClick: () => void;
  disabled: boolean;
  loading: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  handleClick,
  loading,
  disabled,
  label,
}) => {
  return (
    <Button
      onClick={handleClick}
      disabled={loading || disabled}
      className="w-full min-h-10 bg-[#2C5F5C] text-white hover:bg-[#23504F]"
    >
      {loading ? (
        <LoaderCircle className="animate-spin" />
      ) : (
        <p className="text-md font-medium">{label}</p>
      )}
    </Button>
  );
};

export default ActionButton;
