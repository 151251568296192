import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";

import { RootState } from "@/store/store";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { Button } from "../ui/button";
import DialogLoaderButton from "../loader-button/DialogLoaderButton";
import { DELIVERY_CONFIRMATION_DIALOG } from "@/constants/constants";

interface DeliveryConfirmationDialogProps {
  handleValidate_: () => void;
}

const DeliveryConfirmationDialog: React.FC<DeliveryConfirmationDialogProps> = ({
  handleValidate_,
}) => {
  const dispatch = useDispatch();
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialog
  );
  const isOpened = isOpen && dialogType === DELIVERY_CONFIRMATION_DIALOG;

  const handleClose = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogDescription>
            Étes-vous sûr de vouloir confirmer la livraison ?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex flex-col sm:flex-row gap-2 sm:gap-0">
          <Button variant="outline" className="mr-2" onClick={handleClose}>
            Annuler
          </Button>
          <DialogLoaderButton
            name="Confirmer"
            handleSubmit={handleValidate_}
            isLoading={false}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeliveryConfirmationDialog;
