import axios, { AxiosResponse } from "axios";
import { store } from "../store/store";

import { list_hub_url } from "../constants/urls";
import { defaultHeadersWithToken } from "../constants/constants";
import { ServerResponse_ } from "@/interfaces/Server";
import { HUB } from "@/interfaces/HUB";

export async function getHUBs(): Promise<
  AxiosResponse<ServerResponse_<HUB[]>>
> {
  const accessToken = store.getState().authentication.accessToken;
  try {
    const response: AxiosResponse<ServerResponse_<HUB[]>> = await axios({
      url: list_hub_url,
      headers: defaultHeadersWithToken(accessToken),
      method: "GET",
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
