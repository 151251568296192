import { Authentication } from "@/interfaces/Authentification";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

export const authPersistConfig = {
  key: "authentication",
  storage,
  whitelist: [
    "roles",
    "fullName",
    "email",
    "username",
    "accessToken",
    "expiresOn",
    "hubId",
    "sellerId",
    "userId",
  ],
};

const userSlice = createSlice({
  name: "user",
  initialState: {
    roles: "",
    fullName: "",
    email: "",
    username: "",
    accessToken: "",
    expiresOn: "",
    hubId: 0,
    sellerId: "",
    userId: "",
    isExpired: false,
    codeOTP: "",
  } as Authentication,
  reducers: {
    authenticate(state: Authentication, action: PayloadAction<Authentication>) {
      const {
        roles,
        fullName,
        email,
        username,
        accessToken,
        expiresOn,
        hubId,
        sellerId,
        userId,
        isExpired,
        codeOTP,
      } = action.payload;
      state.roles = roles;
      state.fullName = fullName;
      state.email = email;
      state.username = username;
      state.accessToken = accessToken;
      state.expiresOn = expiresOn;
      state.hubId = hubId;
      state.sellerId = sellerId;
      state.userId = userId;
      state.isExpired = isExpired;
      state.codeOTP = codeOTP;
    },
    unauthenticate(state: Authentication) {
      state.roles = "";
      state.fullName = "";
      state.email = "";
      state.username = "";
      state.accessToken = "";
      state.expiresOn = "";
      state.hubId = 0;
      state.sellerId = "";
      state.userId = "";
      state.isExpired = false;
      state.codeOTP = "";
    },
    setRoles(state: Authentication, action: PayloadAction<string>) {
      state.roles = action.payload;
    },
  },
});

export const { authenticate, unauthenticate, setRoles } = userSlice.actions;
export default userSlice.reducer;
