import axios, { AxiosResponse } from "axios";
import { supprimer_tournee_url } from "../constants/urls";
import { store } from "../store/store";
import { defaultHeadersWithToken } from "../constants/constants";
import { ServerResponse } from "@/interfaces/Server";

export async function supprimerTournee(numTournee: string): Promise<boolean> {
  const accessToken = store.getState().authentication.accessToken;
  try {
    const response: AxiosResponse<ServerResponse> = await axios({
      url: supprimer_tournee_url,
      headers: defaultHeadersWithToken(accessToken),
      data: { numTournee: numTournee },
      method: "POST",
    });

    let data = response.data;

    if (data.success === true && data.message === "Ok") {
      return data.success;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}
