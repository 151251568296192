import axios, { AxiosResponse } from "axios";
import { hub_reception_confirmation_url } from "../constants/urls";

import { store } from "../store/store";
import { defaultHeadersWithToken } from "../constants/constants";
import { ValidationData } from "@/interfaces/Return";
import { ServerResponse } from "@/interfaces/Server";

export async function confirmerReceptionHUB(
  returnData: ValidationData
): Promise<AxiosResponse<ServerResponse>> {
  const accessToken = store.getState().authentication.accessToken;
  try {
    const response: AxiosResponse<ServerResponse> = await axios({
      url: hub_reception_confirmation_url,
      headers: defaultHeadersWithToken(accessToken),
      data: returnData,
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
