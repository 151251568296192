export const handleFileDownload = (fileUrl: string, fileName: string): void => {
  const link = document.createElement("a");

  link.href = fileUrl;
  link.download = fileName;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};
