import axios from "axios"; // Assuming Axios is installed
import {
  list_tournees_entrepot_url,
  list_tournees_hub_url,
  list_tournees_livreur_url,
  list_tournees_vendeur_url,
} from "../constants/urls";
import { store } from "../store/store";
import { defaultHeadersWithToken } from "../constants/constants";

export const getSellerReturns = async () => {
  const accessToken = store.getState().authentication.accessToken;

  try {
    const response = await axios.get(list_tournees_vendeur_url, {
      headers: defaultHeadersWithToken(accessToken),
    });

    if (response.data.success) {
      return response.data.data; // Assuming 'data' property contains return data
    } else {
      null; // More specific error handling
    }
  } catch (error) {
    null; // More specific error handling
  }

  return []; // Consider returning an empty array on error or default data
};

export const getWarehouseReturns = async () => {
  const accessToken = store.getState().authentication.accessToken;

  try {
    const response = await axios.get(list_tournees_entrepot_url, {
      headers: defaultHeadersWithToken(accessToken),
    });

    if (response.data.success) {
      return response.data.data;
    } else {
      null; // More specific error handling
    }
  } catch (error) {
    null; // More specific error handling
  }

  return []; // Consider returning an empty array on error or default data
};

export const getDeliveryReturns = async () => {
  const accessToken = store.getState().authentication.accessToken;

  try {
    const response = await axios.get(list_tournees_livreur_url, {
      headers: defaultHeadersWithToken(accessToken),
    });

    if (response.data.success) {
      return response.data.data; // Assuming 'data' property contains return data
    } else {
      null; // More specific error handling
    }
  } catch (error) {
    null; // More specific error handling
  }

  return []; // Consider returning an empty array on error or default data
};

export const getHubReturns = async () => {
  const accessToken = store.getState().authentication.accessToken;

  try {
    const response = await axios.get(list_tournees_hub_url, {
      headers: defaultHeadersWithToken(accessToken),
    });

    if (response.data.success) {
      return response.data.data; // Assuming 'data' property contains return data
    } else {
      null; // More specific error handling
    }
  } catch (error) {
    null; // More specific error handling
  }

  return []; // Consider returning an empty array on error or default data
};
