import { Article } from "@/interfaces/Product";
import { Button } from "../ui/button";
import { Plus, Minus } from "lucide-react";
import { useEffect, useState } from "react";
import { toast } from "@/hooks/use-toast";

interface ProductProps {
  product: Article;
  setValidProducts: React.Dispatch<React.SetStateAction<Article[]>>;
  disabled?: boolean;
  forLitige?: boolean;
}
const AdjustableProduct: React.FC<ProductProps> = ({
  product,
  setValidProducts,
  disabled,
  forLitige,
}) => {
  const [quantity, setQuantity] = useState<number>(1);

  const handleIncrease = () => {
    // First check if forLitige and if the new quantity would exceed product quantity
    if (forLitige && product.quantity && quantity + 1 > product.quantity) {
      toast({
        title: "Quantité maximale atteinte",
        variant: "destructive",
      });
      return;
    }

    // If we pass the check or forLitige is false, proceed with the increase
    setQuantity((prev) => prev + 1);
    setValidProducts((prev) => {
      if (prev.some((p) => p.genCode === product.genCode)) {
        return prev.map((p) =>
          p.genCode === product.genCode
            ? { ...p, quantity: (p.quantity ?? 0) + 1 }
            : p
        );
      } else {
        return [...prev, { ...product, quantity: 1 }];
      }
    });
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
      setValidProducts((prev) =>
        prev.map((p) =>
          p.genCode === product.genCode
            ? { ...p, quantity: (p.quantity ?? 0) - 1 }
            : p
        )
      );
    }
    if (quantity === 1) {
      setValidProducts((prev) =>
        prev.filter((p) => p.genCode !== product.genCode)
      );
    }
  };

  useEffect(() => {
    if (forLitige) {
      setQuantity(product.quantity ?? 1);
    }
  }, []);

  return (
    <div className="w-full flex  rounded-lg shadow-md  h-auto bg-white hover:bg-gray-100 my-2 border border-primary p-3 flex-row gap-4">
      <div className="flex  items-center space-x-2 justify-center  align-middle">
        <img src={product.image} alt="product" className="w-20 h-20" />
      </div>
      <div className="flex  justify-between w-full flex-col">
        <p className="text-base font-bold text-gray-700">{product.genCode}</p>
        <p className="text-sm text-gray-500 font-semibold">
          {product.articleName}
        </p>
      </div>
      <div className="flex flex-row justify-center items-center">
        <Button variant="outline" onClick={handleIncrease} disabled={disabled}>
          <Plus className="h-4 w-4" />
        </Button>
        <p className="text-sm text-gray-500 font-semibold text-right mx-4">
          {quantity}
        </p>
        <Button variant="outline" onClick={handleDecrease} disabled={disabled}>
          <Minus className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

export default AdjustableProduct;
