import DeleteConfirmationDialog from "@/components/dialogs/DeleteConfirmationDialog";
import ReturnCreationDialog from "@/components/dialogs/ReturnCreationDialog";
import Return from "@/components/return/Return";
import ReturnSkeleton from "@/components/return/ReturnSkeleton";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Toaster } from "@/components/ui/toaster";
import { IReturn } from "@/interfaces/Return";
import { resetReturnHeader } from "@/store/reducers/returnHeaderReducer";
import { resetArticles } from "@/store/reducers/returnProductsReducer";
import { resetReturn } from "@/store/reducers/returnReducer";
import { RootState } from "@/store/store";
import { fetchReturnList } from "@/utils/return-list-fetching";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

interface DashboardProps {
  // Define any props for the Dashboard component here
}

const Dashboard: React.FC<DashboardProps> = () => {
  const dispatch = useDispatch();
  const [returnList, setReturnList] = useState<IReturn[]>([] as IReturn[]);
  const [isLoading, setIsLoading] = useState(true);
  const { roles } = useSelector((state: RootState) => state.authentication);
  const isRefreshing = useSelector(
    (state: RootState) => state.refresh.isRefreshing
  );
  console.log("returnList", returnList);

  const resetPersistStates = () => {
    dispatch(resetReturnHeader());
    dispatch(resetArticles());
    dispatch(resetReturn());
  };

  useEffect(() => {
    fetchReturnList(setIsLoading, setReturnList, roles);
  }, [isRefreshing]);

  useEffect(() => {
    resetPersistStates();
  }, []);

  return (
    <>
      <ScrollArea className="flex-grow px-2">
        {!isLoading ? (
          <div className="p-2">
            {returnList && returnList.length !== 0 ? (
              returnList.map((item) => (
                <Return key={item.numTournee} return_={item} />
              ))
            ) : (
              <div className="text-center text-gray-500 font-sans">
                No returns found
              </div>
            )}
          </div>
        ) : (
          <div className="p-2">
            {Array.from({ length: 5 }).map((_, index) => (
              <ReturnSkeleton key={index} />
            ))}
          </div>
        )}
      </ScrollArea>
      <ReturnCreationDialog />
      <DeleteConfirmationDialog />
      <Toaster />
    </>
  );
};

export default Dashboard;
