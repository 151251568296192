import { IReturn } from "@/interfaces/Return";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

export const returnPersistConfig = {
  key: "return",
  storage,
  whitelist: ["return"],
};

export interface ReturnState {
  return: IReturn;
}

const initialState: ReturnState = {
  return: {} as IReturn,
};

const returnSlice = createSlice({
  name: "returnProducts",
  initialState,
  reducers: {
    setReturn(state, action: PayloadAction<IReturn>) {
      state.return = action.payload;
    },
    resetReturn(state) {
      state.return = {} as IReturn;
    },
  },
});

export const { setReturn, resetReturn } = returnSlice.actions;
export default returnSlice.reducer;
