import React from "react";
import { Skeleton } from "../ui/skeleton";

const ReturnSkeleton: React.FC = () => {
  return (
    <div className=" shadow-md  rounded-lg bg-white p-3 my-2 min-h-[100px] flex justify-between flex-col mx-2">
      <div className="flex items-center space-x-2 justify-between w-full">
        <Skeleton className="h-4 w-[150px]" />
        <Skeleton className="h-4 w-[100px]" />
      </div>
      <div className="flex items-left space-x-2 w-full">
        <Skeleton className="h-4 w-[100px]" />
      </div>
      <div className="flex justify-between items-center w-full">
        <Skeleton className="h-4 w-[100px]" />
        <Skeleton className="h-4 w-[100px]" />
      </div>
    </div>
  );
};

export default ReturnSkeleton;
