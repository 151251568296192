import { IReturn } from "@/interfaces/Return";
import {
  DELIVERY_ROLE,
  HUB_ROLE,
  SELLER_ROLE,
  WAREHOUSE_ROLE,
} from "../constants/constants";

import {
  getDeliveryReturns,
  getHubReturns,
  getSellerReturns,
  getWarehouseReturns,
} from "../services/return-list.service";

export const fetchReturnList = async (
  setIsLoading: any,
  setReturnList: any,
  roles: string
) => {
  try {
    setIsLoading(true);
    const roleMap: { [key: string]: () => Promise<IReturn[]> } = {
      [SELLER_ROLE]: getSellerReturns,
      [DELIVERY_ROLE]: getDeliveryReturns,
      [WAREHOUSE_ROLE]: getWarehouseReturns,
      [HUB_ROLE]: getHubReturns,
    };

    let fetchFunction: (() => Promise<IReturn[]>) | null = null;

    if (roles.includes(WAREHOUSE_ROLE)) {
      fetchFunction = roleMap[WAREHOUSE_ROLE];
    } else if (roles.includes(DELIVERY_ROLE)) {
      fetchFunction = roleMap[DELIVERY_ROLE];
    } else if (roles.includes(SELLER_ROLE)) {
      fetchFunction = roleMap[SELLER_ROLE];
    } else if (roles.includes(HUB_ROLE)) {
      fetchFunction = roleMap[HUB_ROLE];
    }

    if (fetchFunction) {
      const returns = await fetchFunction();

      setReturnList([...returns]);
    } else {
      setReturnList([]);
    }
  } catch (error) {
    setReturnList([]);
    // Display an error message to the user (optional)
  } finally {
    setIsLoading(false);
  }
};
