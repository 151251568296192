import { DELETE_CONFIRMATION_DIALOG } from "@/constants/constants";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { RootState } from "@/store/store";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import DialogLoaderButton from "../loader-button/DialogLoaderButton";
import { Button } from "../ui/button";
import { supprimerTournee } from "@/services/return-delete.service";
import { setRefreshing } from "@/store/reducers/refreshReducer";
import { toast } from "@/hooks/use-toast";

interface DeleteConfirmationDialogProps {}

const DeleteConfirmationDialog: React.FC<
  DeleteConfirmationDialogProps
> = ({}) => {
  const dispatch = useDispatch();
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialog
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const isRefreshing = useSelector(
    (state: RootState) => state.refresh.isRefreshing
  );
  const isOpened = isOpen && dialogType === DELETE_CONFIRMATION_DIALOG;
  const return_ = useSelector((state: RootState) => state.returnHeader);

  const handleClose = () => {
    dispatch(closeDialog());
  };
  const handleDeleteReturn = async () => {
    setIsLoading(true);
    const response = await supprimerTournee(
      return_.returnCode.toString() || ""
    );
    if (response === true) {
      setIsLoading(false);
      dispatch(setRefreshing(!isRefreshing));
      toast({
        title: "Tournée supprimée avec succès",
        variant: "success",
      });
    } else {
      setIsLoading(false);
      toast({
        title: "Erreur lors de la suppression de la tournée",
        variant: "destructive",
      });
    }
    dispatch(closeDialog());
  };

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Suppression du tournée</DialogTitle>
          <DialogDescription>
            Étes-vous sûr de vouloir supprimer la tournée ?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex flex-col sm:flex-row gap-2 sm:gap-0">
          <Button variant="outline" className="mr-2" onClick={handleClose}>
            Annuler
          </Button>
          <DialogLoaderButton
            variant="destructive"
            name="Supprimer"
            handleSubmit={handleDeleteReturn}
            isLoading={isLoading}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
