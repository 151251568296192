import { toast } from "@/hooks/use-toast";
import { Article } from "@/interfaces/Product";
import { productValidation } from "@/utils/product-scan";
import { ScanBarcode } from "lucide-react";
import React from "react";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { ScrollArea } from "../ui/scroll-area";
import Product from "../product/Product";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";

interface ProductScannerProps {
  validProducts: Article[];
  setValidProducts: React.Dispatch<React.SetStateAction<Article[]>>;
  disabled?: boolean;
}

const ProductScanner: React.FC<ProductScannerProps> = ({
  validProducts,
  setValidProducts,
  disabled,
}) => {
  const [barcode, setBarcode] = React.useState("");
  const return_ = useSelector((state: RootState) => state.return.return);

  const handleBarcodeChange = (value: string) => {
    setBarcode(value.trim());
  };
  const addProductToList = (barcode: string) => async () => {
    const valid = productValidation(return_.articles, barcode, validProducts);
    console.log("valid", valid);
    console;
    if (valid === 1) {
      toast({
        title: "Produit déjà scanné",
        variant: "alert",
      });
      resetInput();
    } else if (valid === 2) {
      toast({
        title: "Produit non trouvé",
        variant: "destructive",
      });
      resetInput();
    } else if (valid !== 1 && valid !== 2) {
      if (typeof valid !== "number") {
        setValidProducts([...validProducts, valid]);
      }
      toast({
        title: "Produit scanné",
        variant: "success",
      });
      console.log("product added to list");

      resetInput();
    }
  };

  const resetInput = () => {
    setBarcode("");
  };

  return (
    <>
      <div className="px-4 flex items-center justify-between gap-2">
        <Input
          placeholder="Chercher un produit par code-barres"
          className="w-full h-10"
          value={barcode}
          onChange={(e) => handleBarcodeChange(e.target.value)}
          disabled={disabled}
        />
        <Button
          variant="outline"
          className="h-10"
          disabled={disabled}
          onClick={addProductToList(barcode)}
        >
          <ScanBarcode className="h-6 w-6" />
        </Button>
      </div>
      <ScrollArea className="flex-grow px-2">
        <div className="p-2">
          {validProducts && validProducts.length > 0 ? (
            validProducts.map((product) => (
              <Product key={product.genCode} product={product} />
            ))
          ) : (
            <div className="flex items-center justify-center h-32">
              <p className="text-center text-gray-500">Aucun produit scanné</p>
            </div>
          )}
        </div>
      </ScrollArea>
    </>
  );
};

export default ProductScanner;
