import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { RETURN_CREATION_DIALOG } from "@/constants/dialog-constants";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "@/store/reducers/dialogReducer";
import DialogLoaderButton from "../loader-button/DialogLoaderButton";
import { RootState } from "@/store/store";
import { getReturnNumber } from "@/services/return-number-generation.service";
import { Seller } from "@/interfaces/Seller";
import { getVendeursWithRequests } from "@/services/vendor-list-with-request.service";
import SellerSelect from "../supplier-select/SellerSelect";
import { LoaderCircle, X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { setReturnHeader } from "@/store/reducers/returnHeaderReducer";
import { NavigationRoutes } from "../navigation/constants";
import { Switch } from "../ui/switch";
import { getRequests } from "@/services/return-request-list.service";
import { ReturnRequest, ReturnRequest_ } from "@/interfaces/Return";
import RequestSelect from "../request-select/RequestSelect";
import { getHUBs } from "@/services/hub-list.service";
import { HUB } from "@/interfaces/HUB";
import DropOffSelect from "../dropoff-select/DropOffSelect";
import { truncateText } from "@/utils/text-utills";
import { ScrollArea } from "../ui/scroll-area";
import Product from "../product/Product";
import { createTourneeViaRequest } from "@/services/return-creation-via-request.service";
import { handleFileDownload } from "@/utils/file-download";
import { setRefreshing } from "@/store/reducers/refreshReducer";
import { toast } from "@/hooks/use-toast";
import { getVendeurs } from "@/services/vendor-list.service";

const ReturnCreationDialog: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [returnCode, setReturnCode] = useState<number>(0);
  const [selectedSeller, setSelectedSeller] = useState<Seller>({} as Seller);
  const [sellersLoading, setSellersLoading] = useState(false);
  const [sellers, setSellers] = useState<Seller[]>([] as Seller[]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [returnCodeLoading, setReturnCodeLoading] = useState(false);
  const [requestsLoading, setRequestsLoading] = useState(false);
  const [selectedRequests, setSelectedRequests] = useState<ReturnRequest[]>(
    [] as ReturnRequest[]
  );
  const [hubLoading, setHubLoading] = useState(false);
  const [dropOffs, setDropOffs] = useState<HUB[]>([] as HUB[]);
  const [requests, setRequests] = useState<ReturnRequest[]>(
    [] as ReturnRequest[]
  );
  const [selectedDropOff, setSelectedDropOff] = useState<HUB>({} as HUB);
  const isRefreshing = useSelector(
    (state: RootState) => state.refresh.isRefreshing
  );
  const [isRequestMode, setIsRequestMode] = useState(false);
  const [showSecondSection, setShowSecondSection] = useState(false);
  const [dialogWidth, setDialogWidth] = useState("sm:max-w-[400px]");
  const [selectedRequest, setSelectedRequest] = useState<ReturnRequest>(
    {} as ReturnRequest
  );
  const [, setMainSectionHeight] = useState(0);
  const mainSectionRef = useRef<HTMLDivElement>(null);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialog
  );
  const isOpened = isOpen && dialogType === RETURN_CREATION_DIALOG;

  const handleCreateReturn = async () => {
    const requests: ReturnRequest_[] = selectedRequests.map((request) => {
      return {
        sellerId: selectedSeller.sellerId,
        headerid: request.num,
      };
    });
    setIsLoading(true);
    const response = await createTourneeViaRequest({
      numTournee: returnCode.toString(),
      sellerId: selectedSeller.sellerId,
      isSellerVIP: selectedSeller.isSellerVIP,
      entityId: selectedDropOff.id,
      demandes: requests,
    });
    if (response.status === 200 && response.data.success === true) {
      response.data.data !== "" && handleFileDownload(response.data.data, "");
      setIsLoading(false);
      dispatch(setRefreshing(!isRefreshing));
      toast({
        title: "Tournée créée avec succès",
        variant: "success",
      });
      handleClose();
    } else {
      setIsLoading(false);
      toast({
        title: "Erreur lors de la création de la tournée",
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };

  const handleRemoveRequest = (request: ReturnRequest) => {
    setSelectedRequests((prev) => prev.filter((r) => r.num !== request.num));
    setSelectedRequest({} as ReturnRequest);
    setShowSecondSection(false);
  };

  const handleClose = () => {
    dispatch(closeDialog());
    setSelectedDropOff({} as HUB);
    setSelectedSeller({} as Seller);
    setReturnCode(0);
    setSelectedRequests([] as ReturnRequest[]);
    setSelectedRequest({} as ReturnRequest);
    setShowSecondSection(false);
    setIsRequestMode(false);
  };

  const handleCancel = () => {
    if (isConfirmed) {
      setIsConfirmed(false);
    } else {
      handleClose();
    }
  };

  const handleSwitchChange = () => {
    setIsRequestMode(!isRequestMode);
    if (isRequestMode) {
      setSelectedRequests([] as ReturnRequest[]);
      setSelectedRequest({} as ReturnRequest);
      setShowSecondSection(false);
      setSelectedSeller({} as Seller);
    }
  };

  const handleShowDetails = (request: ReturnRequest) => {
    setSelectedRequest(request);
    if (request.num === selectedRequest.num) {
      setShowSecondSection(!showSecondSection);
    } else {
      setShowSecondSection(true);
    }
  };

  const handleRedirectToScanPage = () => {
    if (!isRequestMode) {
      dispatch(closeDialog());
      dispatch(
        setReturnHeader({
          seller: selectedSeller,
          returnCode: returnCode,
        })
      );
      navigate(NavigationRoutes.RETURN_CREATE);
    } else {
      if (isConfirmed === false) {
        setIsConfirmed(true);
      } else {
        handleCreateReturn();
      }
    }
  };

  const checkIfDisabled = () => {
    if (!isRequestMode) {
      if (selectedSeller.sellerName && returnCode !== 0) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      if (
        selectedSeller.sellerId &&
        returnCode !== 0 &&
        selectedRequests.length > 0
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
    console.log(
      "nan",
      selectedSeller.sellerId && returnCode !== 0 && selectedRequests.length > 0
    );
  };

  const generateReturnCode = async () => {
    setReturnCodeLoading(true);
    const response = await getReturnNumber();
    if (response.status === 200 && response.data.success === true) {
      setReturnCode(response.data.data.num_tournee);
      setReturnCodeLoading(false);
    } else {
      setReturnCodeLoading(false);
    }
    setReturnCodeLoading(false);
  };
  //
  const fetchSellersWithRequests = async () => {
    setSellersLoading(true);
    const response = await getVendeursWithRequests();
    if (response.status === 200 && response.data.success === true) {
      setSellers(response.data.data);
      setSellersLoading(false);
    } else {
      setSellersLoading(false);
    }
    setSellersLoading(false);
  };

  const fetchSellers = async () => {
    setSellersLoading(true);
    const response = await getVendeurs();
    if (response.status === 200 && response.data.success === true) {
      setSellers(response.data.data);
      setSellersLoading(false);
    } else {
      setSellersLoading(false);
    }
    setSellersLoading(false);
  };

  const fetchRequests = async () => {
    setRequestsLoading(true);
    const response = await getRequests(parseInt(selectedSeller.sellerId));

    if (response.status === 200 && response.data.success === true) {
      setRequests(response.data.data);
      setRequestsLoading(false);
    } else {
      setRequestsLoading(false);
    }
    setRequestsLoading(false);
  };

  const fetchHUBs = async () => {
    setHubLoading(true);
    const response = await getHUBs();
    if (response.status === 200 && response.data.success === true) {
      setDropOffs(response.data.data);
      setHubLoading(false);
    } else {
      setHubLoading(false);
    }
    setHubLoading(false);
  };

  useEffect(() => {
    if (isOpened === true) {
      generateReturnCode();
      fetchHUBs();
    }
  }, [isOpened]);

  useEffect(() => {
    if (isRequestMode === true) {
      fetchSellersWithRequests();
    } else {
      fetchSellers();
    }
  }, [isRequestMode]);

  useEffect(() => {
    checkIfDisabled();
    if (
      selectedSeller.sellerId &&
      isRequestMode === true &&
      requests.length === 0
    ) {
      fetchRequests();
    }
    resetSection();
  }, [selectedSeller, isRequestMode, selectedRequests]);

  const resetSection = () => {
    setSelectedRequest({} as ReturnRequest);
    setShowSecondSection(false);
  };

  useEffect(() => {
    if (selectedRequests.length === 0) {
      setSelectedRequest({} as ReturnRequest);
      setShowSecondSection(false);
      setIsConfirmed(false);
    }
  }, [selectedRequests]);

  useEffect(() => {
    setDialogWidth(showSecondSection ? "sm:max-w-[800px]" : "sm:max-w-[500px]");
  }, [showSecondSection]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (mainSectionRef.current) {
        setMainSectionHeight(mainSectionRef.current.offsetHeight);
      }
    });

    if (mainSectionRef.current) {
      resizeObserver.observe(mainSectionRef.current);
    }

    return () => resizeObserver.disconnect();
  }, [selectedRequest]);

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent
        className={`${dialogWidth} transition-all duration-300 flex flex-col`}
        hasClose
      >
        <DialogHeader>
          <DialogTitle>
            <div className="flex justify-between">
              <p className="text-lg font-semibold">Crée une tournée</p>

              <div className="flex items-center gap-2">
                <Label className="font-normal">Par demande</Label>
                <Switch
                  checked={isRequestMode}
                  onCheckedChange={handleSwitchChange}
                />
              </div>
            </div>
          </DialogTitle>
        </DialogHeader>

        {/* Main content area - Now using auto height */}
        <div
          className={`flex gap-4 ${showSecondSection ? "h-[350px]" : "h-auto"}`}
        >
          {/* Left section - Using auto height when no second section */}
          <ScrollArea
            className={`flex-1 ${showSecondSection ? "overflow-y-auto" : ""}`}
            showScrollIndicator
          >
            <div className="flex flex-col gap-4">
              <div className="space-y-1">
                <Label>Numero Tournée:</Label>
                <div className="flex items-center space-x-2">
                  <Input value={returnCode} disabled className="w-full" />
                  {returnCodeLoading && (
                    <LoaderCircle
                      className="animate-spin"
                      color="grey"
                      size={20}
                    />
                  )}
                </div>
              </div>

              <div>
                <SellerSelect
                  sellers={sellers}
                  selectedSeller={selectedSeller}
                  setSelectedSeller={setSelectedSeller}
                  loading={sellersLoading}
                />
              </div>

              {isRequestMode && selectedSeller.sellerId && (
                <div className="flex flex-col gap-4">
                  <div>
                    <RequestSelect
                      requests={requests}
                      selectedRequests={selectedRequests}
                      setSelectedRequests={setSelectedRequests}
                      loading={requestsLoading}
                    />
                  </div>

                  {selectedRequests.length > 0 && (
                    <div className="space-y-2">
                      {selectedRequests.map((request) => (
                        <div
                          key={request.num}
                          className="flex items-center gap-2"
                        >
                          <Button
                            variant="outline"
                            className="w-full items-left"
                            onClick={() => handleShowDetails(request)}
                          >
                            {truncateText(
                              "Demande N°" + request.num.toString(),
                              20
                            )}
                          </Button>
                          <Button
                            variant="outline"
                            className="hover:bg-white border-none"
                            onClick={() => handleRemoveRequest(request)}
                          >
                            <X className="w-4 h-4" />
                          </Button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}

              <div>
                <DropOffSelect
                  dropOffs={dropOffs}
                  selectedDropOff={selectedDropOff}
                  setSelectedDropOff={setSelectedDropOff}
                  loading={hubLoading}
                />
              </div>
            </div>
          </ScrollArea>

          {/* Right section */}
          {showSecondSection && (
            <ScrollArea className="flex-1 border border-gray-400 rounded-lg p-4">
              {selectedRequest?.products?.map((product) => (
                <Product key={product.genCode} product={product} />
              ))}
            </ScrollArea>
          )}
        </div>

        <DialogFooter className="flex flex-col sm:flex-row gap-2 mt-4 items-center justify-between sm:justify-between">
          <div className="flex items-center gap-2">
            {isConfirmed && (
              <p className="text-sm text-gray-500">
                Voulez-vous vraiment créer une tournée avec ces demandes?
              </p>
            )}
          </div>
          <div className="flex gap-2">
            <Button variant="outline" onClick={handleCancel}>
              Annuler
            </Button>
            {!isConfirmed ? (
              <DialogLoaderButton
                isLoading={isLoading}
                disabled={isDisabled}
                handleSubmit={handleRedirectToScanPage}
                name={isRequestMode ? "Confirmer" : "Suivant"}
              />
            ) : (
              <DialogLoaderButton
                isLoading={isLoading}
                disabled={isDisabled || isLoading}
                handleSubmit={handleRedirectToScanPage}
                name={"Créer"}
              />
            )}
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ReturnCreationDialog;
