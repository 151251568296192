export const base_url: string =
  'https://reprise-vendeur-api.azurewebsites.net/';
export const login_url: string = base_url + 'api/Authentification/login';
export const register_url: string = base_url + 'api/Authentification/register';
export const reset_pw_url: string =
  base_url + 'api/Authentification/resetPassword';
export const confirmer_reception_url: string =
  base_url + 'api/TRVendeur/VENDEURValiderTourneeRetour';
export const list_motif_url: string =
  base_url + 'api/TRVendeur/VENDEURMotifTourneeRetour';
export const create_litige_url: string =
  base_url + 'api/TRVendeur/VENDEURCreateLitigeTourneeRetour';
export const product_details_url: string =
  base_url + 'api/TREntrepot/TRGetArticleTourneeRetour';
export const liste_vendeurs_url: string =
  base_url + 'api/TREntrepot/TRSellerTourneeRetour';
export const liste_vendeurs_avec_demandes_url: string =
  base_url + 'api/TREntrepot/TRSellerTourneeDemandesRetour';
export const create_tournee_url: string =
  base_url + 'api/TREntrepot/TRCreateTourneeRetour';
export const generate_tournee_code_url: string =
  base_url + 'api/TREntrepot/TRGetNumTourneeRetour';
export const confirmer_livraison_url: string =
  base_url + 'api/TRLivreur/LIVREURValiderTourneeRetour';
export const list_tournees_vendeur_url: string =
  base_url + 'api/TRVendeur/VENDEURTourneeRetourRecuVendeur';
export const list_tournees_entrepot_url: string =
  base_url + 'api/TREntrepot/TRListTourneeRetour';
export const list_tournees_livreur_url: string =
  base_url + 'api/TRLivreur/LIVREURTourneeRetourEnCoursLivraison';
export const list_tournees_hub_url: string =
  base_url + 'api/TRHub/HUBLivreurTourneeRetourRecuHub';
export const supprimer_tournee_url: string =
  base_url + 'api/TREntrepot/TRRemoveTourneeRetour';
export const list_hub_url: string = base_url + 'api/TRHub/HUBListHub';
export const hub_reception_confirmation_url: string =
  base_url + 'api/TRHub/HUBValiderTourneeRetour';
export const hub_non_vip_seller_validation_url: string =
  base_url + 'api/TRHub/HubValiderTRforSellerNoVIP';
export const delivery_non_vip_seller_validation_url: string =
  base_url + 'api/TRLivreur/LivreurValiderTRforSellerNoVIP';
export const return_request_url: string =
  base_url + 'api/TREntrepot/TRListDemandesRepriseByCodeSeller?codeSeller=';
export const create_tournee_via_request_url: string =
  base_url + 'api/TREntrepot/TRCreateTourneeRetourViaDemandes';
