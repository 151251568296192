import { Article } from "@/interfaces/Product";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

export const returnProductsPersistConfig = {
  key: "returnProducts",
  storage,
  whitelist: ["articles"],
};

export interface ReturnProductsState {
  articles: Article[];
}

const initialState: ReturnProductsState = {
  articles: [] as Article[],
};

const returnProductsSlice = createSlice({
  name: "returnProducts",
  initialState,
  reducers: {
    addArticle(state, action: PayloadAction<Article>) {
      state.articles.push(action.payload);
    },
    removeArticle(state, action: PayloadAction<Article>) {
      state.articles = state.articles.filter(
        (article) => article.genCode !== action.payload.genCode
      );
    },
    updateArticle(state, action: PayloadAction<Article>) {
      const index = state.articles.findIndex(
        (article) => article.genCode === action.payload.genCode
      );
      if (index !== -1) {
        state.articles[index] = action.payload;
      }
    },
    setArticles(state, action: PayloadAction<Article[]>) {
      state.articles = action.payload;
    },
    resetArticles(state) {
      state.articles = [] as Article[];
    },
  },
});

export const {
  addArticle,
  removeArticle,
  updateArticle,
  setArticles,
  resetArticles,
} = returnProductsSlice.actions;
export default returnProductsSlice.reducer;
