import React from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { cn } from "@/lib/utils";
import {
  CommandInput,
  CommandEmpty,
  CommandList,
  CommandGroup,
  CommandItem,
  Command,
} from "../ui/command";
import { ChevronsUpDownIcon, Check, LoaderCircle } from "lucide-react";
import { Button } from "../ui/button";
import { ReturnRequest } from "@/interfaces/Return";

interface RequestSelectProps {
  requests?: ReturnRequest[];
  selectedRequests: ReturnRequest[];
  setSelectedRequests: (request: ReturnRequest[]) => void;
  loading?: boolean;
}

const RequestSelect: React.FC<RequestSelectProps> = ({
  requests,
  selectedRequests,
  setSelectedRequests,
  loading,
}) => {
  const [requestOpen, setRequestOpen] = React.useState(false);

  const handleItemSelect = (request: ReturnRequest) => {
    if (!checkExistance(request))
      setSelectedRequests([...selectedRequests, request]);
    if (checkExistance(request)) {
      setSelectedRequests(
        selectedRequests.filter((r) => r.num !== request.num)
      );
    }
    setRequestOpen(false);
  };

  const checkExistance = (request: ReturnRequest) => {
    return selectedRequests.some((r) => r.num === request.num);
  };

  return (
    <div className="grid gap-1 ">
      <label className="text-sm font-medium" htmlFor="supplier">
        Demande
      </label>
      <div className="flex items-center justify-between gap-2">
        <Popover open={requestOpen} onOpenChange={setRequestOpen}>
          <PopoverTrigger asChild>
            <Button
              className="w-full justify-between "
              role="combobox"
              variant="outline"
            >
              {"Choisir une demande"}
              <ChevronsUpDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height]">
            <Command>
              <CommandInput
                className="h-9"
                placeholder="Chercher une demande"
              />
              <CommandEmpty>Aucune demande trouvée</CommandEmpty>
              <CommandList>
                <CommandGroup>
                  {requests &&
                    requests.map((request) => (
                      <CommandItem
                        key={request.num}
                        value={request.num.toString() || ""}
                        onSelect={() => handleItemSelect(request)}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            checkExistance(request)
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {"Demande N°: " + request.num}
                      </CommandItem>
                    ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        {loading && (
          <LoaderCircle className="animate-spin" color="grey" size={20} />
        )}
      </div>
    </div>
  );
};

export default RequestSelect;
