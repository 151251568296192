import React from "react";

interface StatusTagProps {
  text: string;
  bakcgroundColor: string;
}

const StatusTag: React.FC<StatusTagProps> = ({ text, bakcgroundColor }) => {
  return (
    <div
      className={`flex items-center space-x-2  rounded-md `}
      style={{ backgroundColor: bakcgroundColor }}
    >
      <p className="rounded-md px-3 py-1 text-sm text-white">{text}</p>
    </div>
  );
};

export default StatusTag;
