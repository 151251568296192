import { Article } from "@/interfaces/Product";
import React from "react";

interface ProductProps {
  product: Article;
}
const Product: React.FC<ProductProps> = ({ product }) => {
  return (
    <div className="w-full flex  rounded-lg shadow-md  h-auto bg-white hover:bg-gray-100 my-2 border border-primary p-3 flex-row gap-4">
      <div className="flex  items-center space-x-2 justify-center  align-middle">
        <img src={product.image} alt="product" className="w-20 h-20" />
      </div>
      <div className="flex  justify-between w-full flex-col">
        <p className="text-base font-bold text-gray-700">{product.genCode}</p>
        <p className="text-sm text-gray-500 font-semibold">
          {product.articleName}
        </p>
        <p className="text-sm text-gray-500 font-semibold text-right">
          Quantité: {product.quantity}
        </p>
      </div>
    </div>
  );
};

export default Product;
