import axios, { AxiosResponse } from "axios";
import { create_tournee_url } from "../constants/urls";

import { store } from "../store/store";
import { defaultHeadersWithToken } from "../constants/constants";
import { ReturnToCreate } from "@/interfaces/Return";
import { ServerResponse } from "@/interfaces/Server";

export async function createTournee(
  tournee: ReturnToCreate
): Promise<AxiosResponse<ServerResponse>> {
  const accessToken = store.getState().authentication.accessToken;

  try {
    const response: AxiosResponse<ServerResponse> = await axios({
      url: create_tournee_url,
      headers: defaultHeadersWithToken(accessToken),
      data: tournee,
      method: "POST",
    });
    return response;
  } catch (error) {
    console.error("Error creating tournee:", error);
    return {} as AxiosResponse<ServerResponse>;
  }
}
