import ActionButton from "@/components/loader-button/ActionButton";
import { NavigationRoutes } from "@/components/navigation/constants";
import Product from "@/components/product/Product";
import { ScrollArea } from "@/components/ui/scroll-area";
import { RootState } from "@/store/store";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const ReturnDetailsPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { toValidate } = (location.state ?? { toValidate: false }) as {
    toValidate: boolean;
  };
  const return_ = useSelector((state: RootState) => state.return.return);
  const handleNavigateToPJPage = () => {
    navigate(NavigationRoutes.PJ);
  };

  return (
    <>
      <div className="px-4 py-4 flex items-start justify-between gap-2 flex-row ">
        <p className="text-lg font-semibold">
          N° de retour: {return_.numTournee}
        </p>
        <p className="text-lg font-semibold">Vendeur: {return_.sellerName}</p>
      </div>
      <ScrollArea className="flex-grow px-2">
        <div className="p-2">
          {return_ && return_.articles.length > 0 ? (
            return_.articles.map((product) => (
              <Product key={product.genCode} product={product} />
            ))
          ) : (
            <p>No products in the return</p>
          )}
        </div>
      </ScrollArea>
      {toValidate && (
        <div className="px-4">
          <ActionButton
            label="Suivant"
            disabled={false}
            handleClick={handleNavigateToPJPage}
            loading={false}
          />
        </div>
      )}
    </>
  );
};

export default ReturnDetailsPage;
