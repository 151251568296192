import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Eye, EyeOff } from "lucide-react";
import logo from "@/assets/logo.png";
import React, { useEffect } from "react";

import {
  DELIVERY_ROLE,
  HUB_ROLE,
  SELLER_ROLE,
  WAREHOUSE_ROLE,
} from "@/constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { authenticate } from "@/store/reducers/authReducer";
import { authenticate_user } from "@/services/auth.service";
import { useNavigate } from "react-router-dom";
import ActionButton from "@/components/loader-button/ActionButton";
import { RootState } from "@/store/store";
import { NavigationRoutes } from "@/components/navigation/constants";

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.authentication);
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const handleLogin = async () => {
    setIsLoading(true);
    setError("");

    try {
      const response = await authenticate_user(email, password);

      if (response.status === 200 && response.data.success === true) {
        const user_data = response.data.authResult;

        if (
          user_data.roles &&
          (user_data.roles.includes(SELLER_ROLE) ||
            user_data.roles.includes(DELIVERY_ROLE) ||
            user_data.roles.includes(WAREHOUSE_ROLE) ||
            user_data.roles.includes(HUB_ROLE))
        ) {
          dispatch(authenticate(user_data));
          navigate(NavigationRoutes.DASHBOARD, { replace: true });
          setEmail("");
          setPassword("");
        } else {
          setError(
            "Vous n'avez pas les droits pour accéder à cette application"
          );
          setTimeout(() => {
            setError("");
          }, 3000);
        }
      } else {
        setError(response.data.msg);
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    } catch (error) {
      console.log(error);
      setError("Erreur de connexion");
      setTimeout(() => {
        setError("");
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const checkIfUserIsAuthenticated = () => {
    if (user.accessToken !== "") {
      navigate(NavigationRoutes.DASHBOARD, { replace: true });
    }
  };

  useEffect(() => {
    checkIfUserIsAuthenticated();
  }, []);

  return (
    <div className="flex min-h-[100dvh] items-center justify-center bg-gradient-to-br from-[#2C5F5C] to-[#7DCBC8] flex-col">
      <div className="w-full min-w-md max-w-md rounded-lg bg-white p-8 shadow-lg dark:bg-gray-950">
        <div className="space-y-4">
          <img src={logo} alt="Logo" className="h-10 w-auto mx-auto mb-8" />
          <div className="space-y-2">
            <Input
              type="email"
              disabled={isLoading}
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full h-10"
              required
            />
          </div>

          <div className="space-y-2">
            <div className="flex gap-2">
              <Input
                disabled={isLoading}
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Mot de passe"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full h-10"
              />
              <Button
                type="button"
                disabled={isLoading}
                variant="outline"
                size="icon"
                onClick={() => setShowPassword(!showPassword)}
                className="shrink-0 h-10 w-10"
              >
                {showPassword ? (
                  <EyeOff className="h-4 w-4" />
                ) : (
                  <Eye className="h-4 w-4" />
                )}
                <span className="sr-only">
                  {showPassword ? "Hide password" : "Show password"}
                </span>
              </Button>
            </div>
          </div>

          <ActionButton
            loading={isLoading}
            disabled={isLoading}
            handleClick={handleLogin}
            label="Se connecter"
          />
          {error && <p className="text-red-500 text-sm text-center">{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
