import { Seller } from "@/interfaces/Seller";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

export const returnHeaderPersistConfig = {
  key: "returnHeader",
  storage,
  whitelist: ["returnCode", "seller"],
};

export interface ReturnHeaderState {
  returnCode: number;
  seller: Seller;
}

const initialState: ReturnHeaderState = {
  returnCode: 0,
  seller: {} as Seller,
};

const returnHeaderSlice = createSlice({
  name: "returnCreate",
  initialState,
  reducers: {
    setReturnHeader: (state, action: PayloadAction<ReturnHeaderState>) => {
      state.returnCode = action.payload.returnCode;
      state.seller = action.payload.seller;
    },
    resetReturnHeader: (state) => {
      state.returnCode = 0;
      state.seller = {} as Seller;
    },
  },
});

export const { setReturnHeader, resetReturnHeader } = returnHeaderSlice.actions;
export default returnHeaderSlice.reducer;
