import axios, { AxiosResponse } from "axios"; // Assuming Axios is installed
import { generate_tournee_code_url } from "../constants/urls";
import { store } from "../store/store";
import { defaultHeadersWithToken } from "../constants/constants";
import { ServerResponse_ } from "@/interfaces/Server";

export const getReturnNumber = async (): Promise<
  AxiosResponse<ServerResponse_<{ num_tournee: number }>>
> => {
  const accessToken = store.getState().authentication.accessToken;

  try {
    const response: AxiosResponse<ServerResponse_<{ num_tournee: number }>> =
      await axios.get(generate_tournee_code_url, {
        headers: defaultHeadersWithToken(accessToken),
      });
    return response;
  } catch (error: any) {
    return error.response;
  }
};
