import axios, { AxiosResponse } from "axios";
import { product_details_url } from "../constants/urls";
import { store } from "../store/store";

import { defaultHeadersWithToken } from "../constants/constants";
import { ServerResponse_ } from "@/interfaces/Server";
import { Article_ } from "@/interfaces/Product";

export async function getProductDetails(
  genCode: string
): Promise<AxiosResponse<ServerResponse_<Article_>>> {
  const accessToken = store.getState().authentication.accessToken;
  try {
    const response: AxiosResponse<ServerResponse_<Article_>> = await axios({
      url: product_details_url,
      headers: defaultHeadersWithToken(accessToken),
      data: { productId: genCode },
      method: "POST",
    });
    return response;
  } catch (error) {
    return {} as AxiosResponse<ServerResponse_<Article_>>;
  }
}
