import { IReturn } from "@/interfaces/Return";
import React from "react";
import { Button } from "../ui/button";
import StatusTag from "../status-tag/StatusTag";
import ProductCounter from "../product-counter/ProductCounter";
import { formateDate } from "@/utils/date-utils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import {
  DELETE_CONFIRMATION_DIALOG,
  HUB_ROLE,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  SELLER_ROLE,
  WAREHOUSE_ROLE,
} from "@/constants/constants";
import { Download, MapPin, Trash2 } from "lucide-react";
import { setReturn } from "@/store/reducers/returnReducer";
import { handleReturnClick } from "@/utils/return-navigation";
import { NavigationRoutes } from "../navigation/constants";
import { handleFileDownload } from "@/utils/file-download";
import { setReturnHeader } from "@/store/reducers/returnHeaderReducer";
import { openDialog } from "@/store/reducers/dialogReducer";

interface ReturnProps {
  return_: IReturn;
}

const Return: React.FC<ReturnProps> = ({ return_ }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roles = useSelector((state: RootState) => state.authentication.roles);
  const onLitigeClick = () => {
    dispatch(setReturn(return_));
    navigate(NavigationRoutes.RETURN_LITIGE);
  };
  const onDeleteClick = () => {
    dispatch(
      setReturnHeader({
        returnCode: return_.numTournee,
        seller: {
          sellerId: return_.sellerId,
          sellerName: return_.sellerName,
          isSellerVIP: return_.isSellerVIP,
        },
      })
    );
    dispatch(openDialog(DELETE_CONFIRMATION_DIALOG));
  };

  const onDownloadClick = () => {
    if (return_.url)
      handleFileDownload(return_.url, return_.numTournee.toString() || "");
  };

  const renderLitigeButton = () => {
    if (
      (return_.statutId === 4 && roles === SELLER_ROLE) ||
      (return_.statutId === 7 && roles === HUB_ROLE)
    ) {
      return (
        <Button
          className="flex items-center space-x-2 border rounded-md px-3 py-1 border-red-500  hover:bg-red-100"
          variant={"outline"}
          onClick={onLitigeClick}
        >
          <p className={`text-sm font-semibold text-red-500`}>Litige</p>
        </Button>
      );
    }
    return null;
  };

  const renderDeleteButton = () => {
    if (return_.statutId === 2 && roles === WAREHOUSE_ROLE) {
      return (
        <Button
          className="flex  border rounded-md bg-white hover:bg-red-50 border-red-500"
          onClick={onDeleteClick}
        >
          <Trash2 className="text-red-500" />
        </Button>
      );
    }
    return null;
  };

  const renderDownloadButton = () => {
    if (return_.statutId === 2 && return_.url && roles === WAREHOUSE_ROLE) {
      return (
        <Button
          className="flex  border rounded-md bg-white hover:bg-teal-50"
          style={{ borderColor: SECONDARY_COLOR }}
          onClick={onDownloadClick}
        >
          <Download color={PRIMARY_COLOR} />
        </Button>
      );
    }
    return null;
  };

  const handleReturnClick_ = () => {
    dispatch(setReturn(return_));
    handleReturnClick(return_, roles, navigate);
  };

  return (
    <div className="w-full flex  rounded-lg shadow-md  h-auto bg-white hover:bg-gray-100 my-2 border border-primary p-2 gap-2 flex-col">
      <div
        className="flex items-center space-x-2 justify-between w-full"
        onClick={handleReturnClick_}
      >
        <h2 className="text-base font-semibold text-gray-700">
          Tournee N° {return_.numTournee}
        </h2>
        <div className="flex items-center space-x-2 flex-row">
          {return_.entityId && (
            <div
              className={`flex items-center   rounded-md px-3 py-1 gap-2`}
              style={{ backgroundColor: "#ebebff" }}
            >
              <MapPin className="h-4 w-4 text-[#5555fd]" />
              <p className="text-sm text-[#5555fd]">{return_.entityName}</p>
            </div>
          )}
          <StatusTag
            text={return_.statutName}
            bakcgroundColor={return_.statutBackground}
          />
        </div>
      </div>
      <div
        className="flex items-left space-x-2 w-full"
        onClick={handleReturnClick_}
      >
        <h3 className="text-md font-bold text-gray-800 mb-1">
          {return_.sellerName}
        </h3>
      </div>
      <div className="flex flex-row gap-2">
        <div
          className="flex justify-between items-center w-full"
          onClick={handleReturnClick_}
        >
          <ProductCounter productCount={return_.countArticles} />
          <div className="flex items-center space-x-2">
            <p className="text-sm text-gray-500 font-bold mr-1">
              {formateDate(return_.createdon)}
            </p>
          </div>
        </div>
        <div className="flex  items-centerflex-row gap-2">
          {renderDownloadButton()}
          {renderDeleteButton()}
          {renderLitigeButton()}
        </div>
      </div>
    </div>
  );
};

export default Return;
