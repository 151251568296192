import { Article, Article_ } from "@/interfaces/Product";

export const convertProduct = (product: Article_) => {
  const article: Article = {
    productId: product.articleId,
    articleName: product.articleLibelle,
    genCode: product.articleGenCode,
    image: product.articleImage,
    numSerie: "",
  };
  return article;
};
