import { Attachement } from "@/interfaces/Attachement";

export const convertFilesToAttachments = async (
  files: File[]
): Promise<Attachement[]> => {
  const attachments: Attachement[] = [];

  for (const file of files) {
    try {
      const base64String = await convertFileToBase64(file);
      attachments.push({
        attachementName: file.name,
        attachement: base64String,
      });
    } catch (error) {
      console.error(`Error converting file ${file.name}:`, error);
    }
  }

  return attachments;
};

export const convertFileToAttachement = async (
  file: File
): Promise<Attachement> => {
  const base64String = await convertFileToBase64(file);
  return {
    attachementName: file.name,
    attachement: base64String,
  };
};

const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result === "string") {
        // Remove "data:image/jpeg;base64," prefix if you don't need it
        const base64 = reader.result.split(",")[1];
        resolve(base64);
      } else {
        reject(new Error("Failed to convert file to base64"));
      }
    };

    reader.onerror = () => {
      reject(new Error("Failed to read file"));
    };

    reader.readAsDataURL(file);
  });
};
