import axios, { AxiosResponse } from "axios"; // Assuming Axios is installed
import { liste_vendeurs_url } from "../constants/urls";
import { store } from "../store/store";
import { defaultHeadersWithToken } from "../constants/constants";
import { ServerResponse_ } from "@/interfaces/Server";
import { Seller } from "@/interfaces/Seller";

export const getVendeurs = async (): Promise<
  AxiosResponse<ServerResponse_<Seller[]>>
> => {
  const accessToken = store.getState().authentication.accessToken;

  try {
    const response: AxiosResponse<ServerResponse_<Seller[]>> = await axios.get(
      liste_vendeurs_url,
      {
        headers: defaultHeadersWithToken(accessToken),
      }
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};
