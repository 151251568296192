import axios, { AxiosResponse } from "axios"; // Assuming Axios is installed
import { list_motif_url } from "../constants/urls";
import { store } from "../store/store";

import { defaultHeadersWithToken } from "../constants/constants";
import { Motif } from "@/interfaces/Motif";
import { ServerResponse_ } from "@/interfaces/Server";

export const getMotifs = async (): Promise<
  AxiosResponse<ServerResponse_<Motif[]>>
> => {
  const accessToken = store.getState().authentication.accessToken;

  try {
    const response: AxiosResponse<ServerResponse_<Motif[]>> = await axios.get(
      list_motif_url,
      {
        headers: defaultHeadersWithToken(accessToken),
      }
    );

    return response;
  } catch (error: any) {
    return error.response;
  }
};
