import { SECONDARY_COLOR } from "@/constants/constants";
import React from "react";

interface ProductCounterTagProps {
  productCount: number;
}

const ProductCounter: React.FC<ProductCounterTagProps> = ({ productCount }) => {
  return (
    <div
      className="flex items-center space-x-2 border rounded-md "
      style={{ borderColor: SECONDARY_COLOR }}
    >
      <p
        className={`rounded-md px-3 py-1 text-sm font-semibold`}
        style={{ color: SECONDARY_COLOR }}
      >
        Produits: {productCount}
      </p>
    </div>
  );
};

export default ProductCounter;
