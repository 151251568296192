import axios, { AxiosResponse } from "axios"; // Assuming Axios is installed
import { login_url } from "../constants/urls";
import { defaultHeaders } from "../constants/constants";
import { AuthResponse } from "@/interfaces/Authentification";

export const authenticate_user = async (username: string, password: string) => {
  try {
    const credentials = {
      Username: username,
      Password: password,
    };

    const response: AxiosResponse<AuthResponse> = await axios.post(
      login_url,
      credentials,
      {
        headers: defaultHeaders,
      }
    );

    return response;
  } catch (error: any) {
    console.error("Error logging in:", error);
    throw error.response; // Re-throw the error for specific handling (optional)
  }
};
