import { Toaster } from "@/components/ui/toaster";
import { Article } from "@/interfaces/Product";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import ActionButton from "@/components/loader-button/ActionButton";
import { ReturnToCreate } from "@/interfaces/Return";
import { createTournee } from "@/services/return-creation.service";
import { setRefreshing } from "@/store/reducers/refreshReducer";
import { toast } from "@/hooks/use-toast";
import { handleFileDownload } from "@/utils/file-download";
import { useNavigate } from "react-router-dom";
import AdjustableProductScanner from "@/components/product-scan/AdjustableProductScanner";
import { NavigationRoutes } from "@/components/navigation/constants";

const ReturnCreatePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isRefreshing = useSelector(
    (state: RootState) => state.refresh.isRefreshing
  );
  const returnHeader = useSelector((state: RootState) => state.returnHeader);
  const [isLoading, setIsLoading] = React.useState(false);
  const [validProducts, setValidProducts] = React.useState<Article[]>(
    [] as Article[]
  );

  const handleCreateReturn = async () => {
    const Return: ReturnToCreate = {
      numTournee: returnHeader.returnCode.toString() || "",
      sellerId: returnHeader.seller.sellerId,
      isSellerVIP: returnHeader.seller.isSellerVIP,
      entityId: null,
      articles: validProducts.map((product) => {
        return {
          productId: product.productId,
          quantity: product.quantity ?? 0,
          numSerie: product.numSerie,
        };
      }),
    };

    setIsLoading(true);

    const response = await createTournee(Return);
    if (response.status === 200 && response.data.success === true) {
      response.data.data !== "" && handleFileDownload(response.data.data, "");
      setIsLoading(false);
      dispatch(setRefreshing(!isRefreshing));
      toast({
        title: "Tournée créée avec succès",
        variant: "success",
      });
      navigate(NavigationRoutes.DASHBOARD, { replace: true });
    } else {
      setIsLoading(false);
      toast({
        title: "Erreur lors de la création de la tournée",
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };

  const validationButtonDisabled = () => {
    return validProducts.length === 0;
  };

  return (
    <div className="flex flex-col h-full gap-4">
      <div className="px-4 py-4 flex items-start justify-between gap-2 flex-row ">
        <p className="text-lg font-semibold">
          N° de retour: {returnHeader.returnCode}
        </p>
        <p className="text-lg font-semibold">
          Vendeur: {returnHeader.seller.sellerName}
        </p>
      </div>
      <AdjustableProductScanner
        validProducts={validProducts}
        setValidProducts={setValidProducts}
        disabled={isLoading}
      />
      <div className="px-4">
        <ActionButton
          loading={isLoading}
          disabled={validationButtonDisabled()}
          handleClick={handleCreateReturn}
          label="Valider"
        />
      </div>
      <Toaster />
    </div>
  );
};

export default ReturnCreatePage;
