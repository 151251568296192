import { Article } from "@/interfaces/Product";

export const checkExistance = (
  barcode: string,
  scannedProducts: Article[]
): boolean => {
  const exist = scannedProducts.find((product) => product.genCode === barcode);
  if (exist) {
    return true;
  }
  return false;
};

export function productValidation(
  products: Article[],
  barcode: string,
  scannedProducts: Article[]
): Article | number {
  const existingProduct = products.find(
    (product) => product.genCode === barcode
  );
  console.log("existingProduct", products);

  if (existingProduct) {
    if (scannedProducts.some((product) => product.genCode === barcode)) {
      return 1;
    }
    return existingProduct;
  } else {
    return 2;
  }
}
